/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  height: 600px;

  .title-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin-top: 20px;
    z-index: 20;

    & > .container {
      padding-right: 0;
      padding-left: 0;

      .row {
        padding-right: 15px;
        padding-left: 15px;

        .title {
          color: white;
        }
      }
    }
  }

  .carousel-caption {
    z-index: 10;
  }

  .carousel-control {
    z-index: 15;
  }

  .item {
    height: 600px;
    background-color: #777;
  }

  .carousel-inner > .item {
    background-size: cover;
    background-position: center;

    & > img {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      height: 600px;
    }
  }

  .carousel-indicators li {
    margin: 1px 10px;
  }

}

#headerCarousel {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}