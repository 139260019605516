section {
	.title {
		margin-top: 70px;
		margin-bottom: 20px;
		font-size: 32px;
		font-weight: 700;
	}

	.title a {
		text-decoration: none;
		color: #000;
	}

}