section.contacts {

	.contacts-title {
		margin-top: 20px!important;
	}

	.contacts-subtitle {

	}

	.map {
		position: relative;

		#map {
			width: 100%;
			height: 420px;
		}

		.google-map {
			position: absolute;
			width: calc(100% - 50px);
			height: 420px;
			background-image: url("/img/map.png");
			background-size: cover;
		}

		.title-wrapper {
			position: absolute;
			top: 30px;
			background-color: #D8D8D8;
			padding: 10px 0px 10px 70px;
			width: 250px;

			.title {
				margin: 0px;
				color: #3B3B3B;
			}
		}
	}

	.address {
		.title {
			margin-top: 0;
			margin-bottom: 20px;
			font-weight: normal;
		}

		border: none;
		background-color: #F2F2F2;
		padding-top: 20px;
		height: 420px;
	}

}