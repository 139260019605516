section#callback {
	background-color: $section-bg;
	padding: 30px 0;

	.title {
		margin-top: 20px;
	}

	form {
		margin-top: 30px;

		.btn {
		}
	}

	input {
		min-width: 250px;
	}
}