
// Body
$body-bg: #ffffff;
$section-bg: darken($body-bg, 5%);
$footer-bg: #1E1E1E;
$footer-height: 360px;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "SF", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #171717;
$text-color-pale: #848484;
$border-color: #E1E1E1;
$footer-color: #C1C1C1;

// Navbar
$navbar-default-bg: #fff;
$navbar-height: 70px;

// Buttons
$btn-default-color: $text-color;
$border-radius-large: 20px;
$padding-large-vertical: 14px;
$padding-large-horizontal: 24px;
$font-size-large: 16px;


$btn-primary-bg: #E61739;
$btn-primary-border: #E61739;
$btn-border-radius-large: 2px;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

$input-height-base: 50px;

// Panels
$panel-default-heading-bg: #fff;
