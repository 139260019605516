
// Fonts
@import "fonts/sf/style";
@import "node_modules/font-awesome/scss/font-awesome"; 

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//app
@import "app/non-responsive";
@import "app/basics";
@import "app/menu";
@import "app/cart";
@import "app/order";
@import "app/sections/basics";
@import "app/sections/carousel";
@import "app/sections/about";
@import "app/sections/products";
@import "app/sections/partners";
@import "app/sections/certificates";
@import "app/sections/contacts";
@import "app/sections/callback";
@import "app/footer";

