footer {
	background-color: $footer-bg;
	padding-top: 30px;
	padding-bottom: 30px;
	color: $footer-color;

	a, .nav > li > a {
		color: white;

		&:hover {
			text-decoration: none;
			color: darken(white, 30%);
			background-color: transparent;
		}
	}

	.nav > li > a { 
		text-transform: uppercase;
	}

	.brand {
		margin-bottom: 30px;
		text-transform: uppercase;
		color: white;
		text-align: center;
	}

	.contacts {
		margin-bottom: 20px;
	}

	.navigation {
		text-align: center;

		.nav {
			display: inline-block;
			float: none;	
		}
	}

	.copyright {
		text-align: center;
		p {
			line-height: 1;
		}
	}
}