nav {
	.navbar-nav > li > a {
		padding: 25px 20px;
		text-transform: uppercase;
	}
}

.index-page {
	.navbar-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		padding-top: 20px;
		z-index: 20;
	}
	
	.navbar-wrapper > .container {
		padding-right: 0;
		padding-left: 0;
	}

	.navbar-wrapper .navbar {
		padding-right: 15px;
		padding-left: 15px;
	}

	.navbar-wrapper .navbar .container {
		width: auto;
	}

	.navbar-default {
		background-color: transparent;
		border: none;

		.navbar-brand {
			margin-left: 0px;
		}

		.navbar-nav > li > a {
			font-size: 16px;
			padding: 25px 15px;
			color: white;
			font-weight: 700;

			&:hover {
				color: #c0c0c0;
			}
		}
	}

	.navbar-flex {
		display: flex;
	    flex-direction: row;
	    justify-content: space-between;

	    .navbar-center-wrapper {
	    	flex: 2 auto;
	    	text-align: center;

	    	.navbar-nav {
	    		display: inline-block;
	    		float: none;
	    	}
	    }
	}

}

.navbar-brand {
	width: 190px;
	height: 60px;
	background-image: url('/img/orichi-logo-white.png');
	/*background-image: url('/img/orichi-logo.png');*/
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.navbar-img-brand {
	width: 190px;
	height: 60px;
	background-image: url("/img/orichi-logo-white.png");
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.navbar-img {
	background-size: cover;
	background-image: url(/img/main-slider/002.jpg);
	background-position: center;
}

.navbar-nav > li > a {
	font-size: 16px;
	padding: 25px 15px;
	color: #ffffff !important;
	font-weight: 700;

	&:hover {
		color: #c0c0c0;
	}
}

.index-page {
	.navbar-brand {	
		background-image: url('/img/orichi-logo-white.png');
	}
}

body:not(.index-page) {
	.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
		margin-top: 5px;
		margin-left: 5px;
	}

	.nav.navbar-center {
		margin-left: 20px;
	}
}