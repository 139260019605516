.row {
	.product {
		min-height: 320px;
	}
}

body.products {
	section.products {
		padding-bottom: 50px;
		border-bottom: 1px solid #eeeeee;

		.tab-content {
			padding-top: 30px;
		}
	}
}

section.products {
	.product {
		.close {
			&:hover {
				background-color: #333;
			}
		}

		.thumb-img {
			img {
				transition: all .2s ease-in-out;
				width: 100%;
				margin-bottom: 20px;

				&:hover { transform: scale(1.1); }
			}
		}

		.name {
			font-size: 18px;
			line-height: 18px;
			font-weight: 500;
			color: $text-color;


			&:hover {
				text-decoration: none;
				color: $brand-primary;
			}
		}

		.sublabel {
			color: $text-color-pale;
			font-size: 16px;
			display: inline-block;
		}

		.subprice {
			display: inline-block;
			color: #000000;
			font-weight: bold;
			font-size: 18px;
		}

	}

	.nav-tabs li {
		font-size: 18px;
		line-height: 18px;
	}

	.btn.more {
		margin-top: 30px;
	}
}

.modal-dialog.modal-product {
	width: 800px;

	.modal-body {
		padding: 30px;
		position: relative;

		.title {
			margin-top: 0px;
			font-size: 28px;
		}

		.photos {
			.image {
				margin-bottom: 20px;
			}
		}

		.description {
			padding-right: 60px;

			.title {
				margin-bottom: 10px;
				font-size: 24px;
			}

			p {
				font-size: 13px;
			}
		}

		h5.label-title {
			font-weight: bold;
			margin-top: 0px;

			small {
				padding-top: 4px;
				display: block;
				font-size: 12px;
				color: #9B9B9B;
			}
		}

		.params {
			.p-label {
				font-size: 12px;
				color: #9B9B9B;
				margin-bottom: 6px;

				.value {
					color: #3B3B3B;
					margin-bottom: 0px;
				}
			}

			.second-line {
				margin-top: 20px;
			}
		}

		.price {
			.label-title {
				margin-bottom: 4px;
			}

			.value {

				font-size: 28px;
				color: #3B3B3B;
			}

			.btn-order {
				margin-top: 20px;
			}
		}

		.close {
			position: absolute;
			top: 20px;
			right: 20px;
			float: none;
			font-size: 32px;
			text-shadow: none;
			opacity: 1;
			filter: none;
			color: white;
			font-weight: 100;
			background-color: #979797;
			border-radius: 100%;
			padding: 0 7px;
			z-index: 100;

			&:focus {
				outline: none;
			}

			span {
				font-size: 24px;
				position: relative;
			    top: -6px;
			    left: 0;
			}
		}
	}
}