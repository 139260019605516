.index-page {
  .dropdown.cart {
    .dropdown-toggle {
      padding: 15px 15px;
      margin-top: 5px;
    }
  }
}

.dropdown.cart {
  &.open > .dropdown-toggle {
    color: white;
    background-color: darken($btn-primary-bg, 10%);
        border-color: darken($btn-primary-border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: white;
      background-color: darken($btn-primary-bg, 17%);
          border-color: darken($btn-primary-border, 25%);
    }
  }

  .dropdown-menu > li > a.btn.btn-primary {
    color: white;
    text-shadow: none;

    &:focus,
    &.focus {
      color: white;
      background-color: darken($btn-primary-bg, 10%);
          border-color: darken($btn-primary-border, 25%);
    }

    &:hover,
    & .btn:hover {
      color: white;
      background-color: darken($btn-primary-bg, 10%);
          border-color: darken($btn-primary-border, 12%);
    }
  }

  .dropdown-toggle {
    color: white;
    text-shadow: none;

    &:focus,
    &.focus {
      color: white;
      background-color: darken($btn-primary-bg, 10%);
          border-color: darken($btn-primary-border, 25%);
    }

    &:hover,
    & .btn:hover {
      color: white;
      background-color: darken($btn-primary-bg, 10%);
          border-color: darken($btn-primary-border, 12%);
    }

    &:active,
    &.active,
    .open > &.dropdown-toggle {
      color: white;
      background-color: darken($btn-primary-bg, 10%);
          border-color: darken($btn-primary-border, 12%);

      &:hover,
      &:focus,
      &.focus {
        color: white;
        background-color: darken($btn-primary-bg, 17%);
            border-color: darken($btn-primary-border, 25%);
      }
    }

    .badge {
      background-color: $text-color;
      color: white;
    }
  }

  .dropdown-menu {
    min-width: 400px;

    .list-group-item {
      border: none;

      tr > td {
        border-bottom: 1px solid $border-color;
      }

      tr:last-child > td {
        border-bottom: none;
      }

      .name {
        margin-top: 20px;
        margin-bottom: 5px;
        font-size: 16px;
      }

      .sublabel {
        color: $text-color-pale;
      }
    }
  }

  table {
    width: 100%;
    padding-left: 25px;
  }
}

.cart-page {
  .dropdown.cart {
    display: none;
  }

  section.cart {
    .table {
      & > tbody {
        & > tr {
          & > td {
            line-height: 2.2;

            input {
              height: 30px;
            }
          }
        }
      }
    }
  }
}