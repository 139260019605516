a:focus {
  outline: none;
}

body {
  background-image: url('/img/bg-tile.png');
  background-repeat: repeat;
  padding: 0;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: $footer-height;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;
}